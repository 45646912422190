<template>
  <vue-line>
    <vue-item>
      <div class="segments-table__name">
        <v-checkbox
            class="segments-table__check"
            :value="checked"
            @input="$emit('select')"
        />
        <router-link
            class="segments-table__link"
            :to="to"
        >
          {{ data.name }}
        </router-link>
      </div>
    </vue-item>
    <vue-item align="right">{{ data.url_count }}</vue-item>
    <vue-item></vue-item>
    <vue-item>
      {{ $moment(data.datetime).format(DATE_FORMAT.DAY_MONTH_SHORT_YEAR_DOT) }}
    </vue-item>
    <vue-item></vue-item>
    <vue-item align="center">
      <div class="segments-table__tools">
        <v-button
          class="segments-table__tools-button"
          type="text-base"
          icon="delete"
          v-tippy
          size="small"
          :content="$t(contentPathSegmentsCatalog)['Delete']"
          padding-left="6px"
          padding-right="6px"
          @click="$emit('delete')"
        />

        <v-button
          class="segments-table__tools-button"
          type="text-base"
          icon="mode"
          v-tippy
          :content="$t(contentPathSegmentsCatalog)['Edit']"
          padding-left="6px"
          padding-right="6px"
          size="small"
          @click="$emit('edit')"
        />
      </div>
    </vue-item>

    <vue-item align="center">
      <v-button
        class="segments-table__more"
        :to="to"
        type="text-base"
        icon="arrow_forward"
        padding-left="6px"
        padding-right="6px"
        size="small"
        @click.native="setCurrentSegment"
      />
    </vue-item>
  </vue-line>
</template>

<script>
import {DATE_FORMAT} from '@/const'
import ROUTE_NAME from '@/router/routeName'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  data () {
    return {
      contentPathSegmentsCatalog,
      DATE_FORMAT,
      ROUTE_NAME
    }
  },

  props: {
    data: {
      type: Object
    },
    to: {
      type: Object
    },
    type: {
      type: String
    },
    segmentsTypesName: {
      type: Object
    },
    checked: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    setCurrentSegment () {
      this.$store.commit('segments/catalog/item/setSegmentData', {
        segment_id: this.data.id,
        has_data: true,
        data: this.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .segments-table {
    &__name {
      max-width: 350px;
      word-wrap: break-word;
      display: flex;
      align-items: center;
    }

    &__check {
      margin-right: 10px;
    }

    &__link {
      max-width: calc(100% - 34px);
      color: #4158D9;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;

      &:hover {
        color: #005db9;
      }
    }

    &__system {
      display: flex;
      align-items: center;
    }

    &__system-icon {
      margin-right: 4px;
    }

    &__tools {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;

      &-button {
        & /deep/ .button__icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__more {
      & /deep/ .button__icon {
        width: 20px;
        height: 20px;
      }
    }

    &__button {
      & /deep/ .button__icon {
        width: 20px;
        height: 20px;
        fill: #bdbdbd !important;
      }
    }
  }
</style>
