<template>
  <form
      class="multiaction"
      @submit.prevent="submit"
  >
    <div class="multiaction__count">{{ data.length }} Выбрано</div>

    <s-select
        v-if="typeOptions.length > 1"
        v-model="type"
        :options="typeOptions"
        placeholder="Выберите действие"
        theme="multiaction"
    />

    <div
        v-else-if="typeOptions.length === 1"
        class="multiaction__action"
    >
      {{ typeOptions[0].name }}
    </div>

    <template v-if="type === ACTION_TYPE.CREATE">
      <s-treeselect
          v-model="$v.form.catalog_id.$model"
          :options="catalog.options"
          placeholder="Выберите категорию"
          theme="multiaction"
          :error="$v.form.catalog_id.$invalid"
          v-tippy="tippyCategory"
      />

      <s-input
          v-model="$v.form.segment_name.$model"
          placeholder="Введите название сегмента"
          static-width="200px"
          theme="multiaction"
          :error="$v.form.segment_name.$invalid"
          v-tippy="tippyName"
      />
    </template>

    <v-button
        name="Применить"
        action="submit"
        :disabled="$v.form.$invalid || disabled"
    />

    <v-button
        v-if="showCompareSegmentsBtn"
        class="multiaction__bi"
        type="main"
        name="Сравнить сегменты"
        :to="{
          name: ROUTE_NAME.BI_TRACTION,
          params: {activeProject: activeProjectId},
          query: {segments: data}
        }"
    />

    <v-button
        class="multiaction__close"
        width="32px"
        size="small"
        padding-right="0"
        padding-left="0"
        icon="close"
        dataforce
        @click="$emit('close')"
    />
  </form>
</template>

<script>
import {mapActions} from 'vuex'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import {transformationInTreeV2} from '@/function'

import {NOTIFY_STATUS} from '@/const'
import ROUTE_NAME from '@/router/routeName'

const DICTIONARY_PATH = 'services.segments.catalog'

const ERROR_TEXT_REQUIRED = 'Поле обязательно'

const ACTION_TYPE = {
  CREATE: 'create',
  MOVE: 'move',
  DELETE: 'delete'
}

export default {
  name: 'multiaction',

  props: {
    data: {
      type: Array,
      default: () => ([])
    },

    actionList: {
      type: Array,
      default: () => ([])
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showCompareSegmentsBtn: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      type: undefined,
      form: {
        segment_name: undefined,
        catalog_id: undefined,
        items: []
      },
      types: [
        {id: ACTION_TYPE.CREATE, name: 'Создать сегмент'},
        {id: ACTION_TYPE.MOVE, name: 'Переместить'},
        {id: ACTION_TYPE.DELETE, name: 'Удалить'}
      ],
      catalog: {
        items: [],
        options: []
      },
      ACTION_TYPE,
      ROUTE_NAME
    }
  },

  validations: {
    form: {
      segment_name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(255)
      },

      catalog_id: {
        required
      },

      items: {
        required
      }
    }
  },

  computed: {
    /* ID активного проекта в URL */
    activeProjectId () {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },

    typeOptions () {
      return this.types.filter(type => this.actionList.includes(type.id))
    },

    tippyCategory () {
      const content = !this.$v.form.catalog_id.required ? ERROR_TEXT_REQUIRED : ''
      return {
        content,
        theme: 'error',
        onShow: () => this.$v.form.catalog_id.$invalid
      }
    },

    tippyName () {
      const content = !this.$v.form.segment_name.required
          ? ERROR_TEXT_REQUIRED
          : !this.$v.form.segment_name.minLength
            ? 'Минимальная длина - 4 символа'
            : !this.$v.form.segment_name.maxLength
              ? 'Максимальная длина - 255 символов'
              : ''
      return {
        content,
        theme: 'error',
        onShow: () => this.$v.form.segment_name.$invalid
      }
    }
  },

  watch: {
    data (val) {
      this.setItems(val)
    }
  },

  async created () {
    this.type = this.typeOptions[0].id || undefined
    this.setItems(this.data)

    if (this.type === ACTION_TYPE.CREATE) {
      await this.getCatalogData()
    }
  },

  methods: {
    ...mapActions({
      getCatalog: 'segments/catalog/getCatalog',
      createTask: 'segments/catalog/createTask'
    }),

    async submit () {
      const form = JSON.parse(JSON.stringify(this.form))

      let payload = {
        body: form,
        query: {project_id: this.activeProjectId},
        headers: {'Content-Type': 'application/json'}
      }

      try {
        await this.createTask(payload)
          .then(() => {
              this.$notify({type: NOTIFY_STATUS.SUCCESS, title: this.$t(DICTIONARY_PATH)['The task of creating the segment is set']})
              this.$emit('close')
            })
          .catch(e => {
            throw e
          })
      } catch (error) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: error || this.$t(DICTIONARY_PATH)['An error occurred']})
      }
    },

    /* Загрузка каталога */
    async getCatalogData () {
      if (this.activeProjectId) {
        try {
          let result = await this.getCatalog({
            modals: true,
            query: {
              project_id: this.activeProjectId
            }
          })

          if (result.has_data) {
            let categories = result.data.items

            this.$set(this, 'catalog', {
              items: categories,
              options: transformationInTreeV2(categories, true)
            })
          } else {
            throw new Error('Каталог сегментов не найден')
          }
        } catch (error) {
          this.$notify({type: 'error', title: error.message})
        }
      }
    },

    setItems (items) {
      this.$set(this.form, 'items', items)
    }
  }
}
</script>

<style scoped lang="scss">
.multiaction {
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;

  &__count {
    color: #D4ECFF;
    font-size: 14px;
  }

  &__close {
    margin-left: auto;
  }



  &__bi {
    position: relative;
    margin-left: 12px;
    background-color: #2962FF;
    overflow: unset;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 12px);
      left: -12px;
      width: 1px;
      height: 24px;
      background-color: #fff;
      opacity: .45;
      z-index: 10;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 32px;
    padding: 0 8px;
    line-height: 24px;
    border: 1px solid #2962ff;
    border-radius: 6px;
    background-color: #2962ff;
    color: #fff;
    font-size: 14px;
  }
}
</style>
