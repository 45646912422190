import {mapState, mapActions, mapGetters} from 'vuex'
import {findOptions, generateTitle, getUser} from '@/function'
import updateUrl from '@/mixins/updateUrl'

import CONSTANTS from '@/const/'
import ROUTE_NAME from '@/router/routeName'
import MODAL_TITLE from '@/modals/modalTitle'

import error from '@/services/errors/block.vue'
import container from '@/services/components/container/'
import tableLine from './components/tableLine/'
import multiaction from '@/services/components/multiaction'
import tableSort from '@/components/tableHeadCell/'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  mixins: [
    updateUrl
  ],

  metaInfo () {
    return {
      title: generateTitle(this.title.meta, this.activeProjectData)
    }
  },

  data () {
    return {
      contentPathSegmentsCatalog,

      preloader: {
        app: false,
        data: false
      },

      formatUrlDates: CONSTANTS.DATE_FORMAT.BASE_FORMAT,

      filters: {
        type: '',
        name: '',
        author_id: '',
        date: {
          from: null,
          to: null
        }
      },
      ROUTE_NAME,
      itemsSelected: [],
      MODAL_TITLE,
      showDialog: false,
      segmentToDelete: {}
    }
  },

  components: {
    'v-error': error,
    'v-container': container,
    'v-table-line': tableLine,
    'v-multiaction': multiaction,
    'v-thead-cell': tableSort
  },

  watch: {
    $route () {
      this.getDataList()
    }
  },

  async created () {
    await this.initData()
  },

  computed: {
    ...mapState({
      data: state => state.segments.catalog.list.data,
      activeProjectData: state => state.active_project_data,
      autors: state => state.segments.catalog.list.autors
    }),

    /* Данные по каталогу */
    ...mapGetters({
      itemCatalog: 'segments/catalog/itemCatalog',
      breadcrumbsCatalog: 'segments/catalog/breadcrumbsCatalog',
      listCatalog: 'segments/catalog/listCatalog'
    }),

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id ? +id : undefined
    },

    /* ID активного сегмента в URL */
    catalogId () {
      let id = +this.$route.params.catalogId
      return id ? +id : undefined
    },

    /* Данные раздела каталога */
    itemCatalogData () {
      return this.itemCatalog(this.catalogId)
    },

    /* Хлебные кроки раздела каталога */
    breadcrumbsCatalogData () {
      return this.breadcrumbsCatalog(this.catalogId)
    },

    catalog () {
      return this.listCatalog
    },

    viewPage () {
      return this.activeProjectId && this.catalogId
    },

    title () {
      let breadcrumbsList = this.breadcrumbsCatalogData
        ? JSON.parse(JSON.stringify(this.breadcrumbsCatalogData.breadcrumbs))
        : [{title: this.itemCatalogData.name}]
      let primary = breadcrumbsList.slice(0, 1).map(i => i.title).join(' / ')
      primary = 'Segments / ' + primary
      let secondary = this.itemCatalogData.name
      let meta = [this.$t(this.contentPathSegmentsCatalog)['Segmentation'], ...breadcrumbsList.map(i => i.title)].slice(-3).join(' / ')

      let breadcrumbs = [
        {title: 'Segments'}
      ].concat(breadcrumbsList)

      breadcrumbs.map(item => {
        switch (true) {
          case item.tab:
            item.path = {
              name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX,
              params: {activeProject: this.activeProjectId},
              query: {catalog_id: item.id}
            }
            break
        }
        return item
      })

      return {
        primary: primary,
        secondary: secondary,
        breadcrumbs: breadcrumbs,
        meta: meta
      }
    },

    /* Фильтры страницы в URL */
    urlFilters () {
      let filters = {date: {}}
      let urlQuery = {...this.$route.query}

      filters.page = urlQuery.page || 1
      filters.name = urlQuery.name || null
      filters.author_id = +urlQuery.author_id || null
      filters.order_by = urlQuery.order_by
      filters.order_direction = urlQuery.order_direction || 'DESC'

      filters.date.from = urlQuery.date_from || undefined
      filters.date.to = urlQuery.date_to || undefined

      return filters
    },

    catalogListTabId () {
      try {
        return findOptions(true, this.breadcrumbsCatalogData.breadcrumbs, 'tab')
      } catch (_) {
        return null
      }
    },

    dialogProps () {
      return {
        html: `${this.$t(contentPathSegmentsCatalog)['Are you sure you want to delete the segment']} <strong>${this.segmentToDelete.name}</strong>?`,
        actionDelete: true,
        buttonTextConfirm: this.$t('modules.pages')['Delete'],
        onConfirm: () => {
          this.removeSegment({
            segmentId: this.segmentToDelete.id,
            query: {
              project_id: this.activeProjectId,
              skip_regionality: 1,
              catalog_id: this.catalogId
            }
          })
              .then(_ => {
            this.$notify({
              type: CONSTANTS.NOTIFY_STATUS.SUCCESS,
              title: this.$t(contentPathSegmentsCatalog)['The task to delete the segment "${segment.name}" is set'].replace('${segment.name}', this.segmentToDelete.name)
            })
          })
              .catch(({message}) => {
            this.$notify({type: CONSTANTS.NOTIFY_STATUS.ERROR, title: message})
          })
              .finally(() => this.clearDialogData())
        },
        onDismiss: this.clearDialogData
      }
    }
  },

  methods: {
    ...mapActions({
      getList: 'segments/catalog/list/getList',
      removeSegment: 'segments/catalog/list/removeSegment'
      // getAuthors: 'segments/catalog/list/getAuthors'
    }),

    /* Изменение URL фильтров */
    updateFilters (filter, value) {
      switch (filter) {
        case 'date':
          this.updateUrl({
            date_from: value.from,
            date_to: value.to
          })
          break

        default:
          this.updateUrl({[filter]: !value ? undefined : value})
      }
    },

    async getDataList () {
      this.preloader.data = true

      let query = {
        project_id: this.activeProjectId
      }

      if (this.urlFilters.order_by) {
        let sort = this.urlFilters.order_by
        if (this.urlFilters.order_direction === 'DESC') {
          sort = '-' + sort
        }
        query.sort = [sort]
      }

      try {
        await this.getList({
          catalogId: this.catalogId,
          query
        })
      } catch ({message}) {
        this.$notify({type: CONSTANTS.NOTIFY_STATUS.ERROR, title: message})
      }

      this.preloader.data = false
    },

    async initData () {
      this.preloader.app = true

      /* Загрузка авторов */
      // if (this.isRootUser || this.isAdminUser) {
      //   try {
      //     await this.getAuthors({
      //       client_id: this.activeProjectData.client_id
      //     })
      //   } catch ({message}) {
      //     this.$notify({type: 'error', title: message})
      //   }
      // }

      /* Установка фильтров */
      this.$set(this, 'filters', {...this.urlFilters})

      /* Загрузка данных таблицы сегментов */
      await this.getDataList()

      this.preloader.app = false
    },

    editSegmentCallback (segment) {
      return () => {
        this.$notify({
          type: CONSTANTS.NOTIFY_STATUS.SUCCESS,
          title: this.$t(contentPathSegmentsCatalog)['The "${segment.name}" segment has been changed'].replace('${segment.name}', segment.name)
        })
        this.$modal.close()
      }
    },

    selectItem (id) {
      const idx = this.itemsSelected.indexOf(id)
      if (idx === -1) {
        this.itemsSelected.push(id)
      } else {
        this.itemsSelected.splice(idx, 1)
      }
    },

    clearItemsSelected () {
      this.itemsSelected = []
    },

    toggleAllItems (val) {
      if (val) {
        this.itemsSelected = this.data.items.map(item => item.id)
      } else {
        this.clearItemsSelected()
      }
    },

    segmentDeleteModal (segment) {
      this.showDialog = true
      this.segmentToDelete = segment
    },

    clearDialogData () {
      this.showDialog = false
      this.segmentToDelete = {}
    }
  }
}
