var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.preloader.app)?_c('div',{staticClass:"dashboard-block _mb-32"},[_c('v-preloader',{staticClass:"_page"})],1):_c('div',{staticClass:"dashboard-container segments-list _column"},[_c('v-page-title',{attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary,"breadcrumbs":!!_vm.title.breadcrumbs,"custom-route-list":_vm.title.breadcrumbs}}),_c('div',{staticClass:"_px-32 _pb-16 _d-flex _justify-start _border-bottom"},[_c('v-button',{attrs:{"to":{ name: _vm.ROUTE_NAME.SEGMENTS_CONTENT_INDEX, query: { catalog_id: _vm.catalogListTabId.id } },"icon":"chevron_left","type":"text-main","dataforce":""}},[_vm._v(" Вернуться к выбору сегмента ")])],1),(_vm.viewPage)?[_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[_c('div',{staticClass:"dashboard-block _mb-22 segments-about _ha"},[(_vm.itemCatalogData)?_c('p',[_vm._v(_vm._s(_vm.itemCatalogData.description))]):_vm._e(),_c('v-button',{staticClass:"segments-about__button",attrs:{"icon":"add","name":_vm.$t(_vm.contentPathSegmentsCatalog)['New segment'],"offset-icon":"4px","padding-left":"8px","dataforce":""},on:{"click":function($event){return _vm.$modal.open({
              name: _vm.MODAL_TITLE.SEGMENT_CREATE,
              right: true,
              props: {
                projectId: _vm.activeProjectId,
                catalogId: _vm.catalogId
              }
            })}}})],1),_c('div',{staticClass:"dashboard-block _mb-32"},[_c('v-container',_vm._b({scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('div',{staticClass:"segments-counts"},[_vm._v(" Всего:  "),_c('strong',[_vm._v(_vm._s(_vm.data.total))])])]},proxy:true},{key:"head-multi-action",fn:function(){return [_c('v-multiaction',{attrs:{"data":_vm.itemsSelected,"actionList":['move', 'delete'],"show-compare-segments-btn":"","disabled":""},on:{"close":_vm.clearItemsSelected}})]},proxy:true}],null,false,750882113)},'v-container',{
            load: _vm.preloader.data,
            hasData: _vm.data.has_data,
            multiAction: !!_vm.itemsSelected.length
          },false),[_c('vue-table',{attrs:{"small-padding":"","head-background":"","hoverable-rows":false},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('vue-line',{attrs:{"type":"head"}},[_c('vue-item',[_c('div',{staticClass:"table-head-cell__name"},[_c('v-checkbox',{staticClass:"cards__table-checkbox",attrs:{"value":_vm.itemsSelected.length === _vm.data.items.length},on:{"input":_vm.toggleAllItems}}),_c('v-thead-cell',{attrs:{"sort":"","sort-column":"","order-by":"name","center":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Segment name'])+" ")])],1)]),_c('vue-item',[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Number of elements'])+" ")]),_c('vue-item',{attrs:{"valign":"center"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['User'])+" ")]),_c('vue-item',[_c('v-thead-cell',{attrs:{"sort":"","sort-column":"","order-by":"datetime","center":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Date'])+" ")])],1),_c('vue-item',[_vm._v("Изменено")]),_c('vue-item',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Actions'])+" ")]),_c('vue-item',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['More'])+" ")])],1)]},proxy:true}],null,false,1443769248)},[_c('template',{slot:"body"},_vm._l((_vm.data.items),function(item,i){return _c('v-table-line',{key:i,attrs:{"data":item,"to":{
                    name: _vm.ROUTE_NAME.SEGMENTS_CONTENT_ITEM,
                    params: {
                      catalogId: _vm.catalogId,
                      segmentId: item.id
                    }
                  },"checked":_vm.itemsSelected.includes(item.id)},on:{"select":function($event){return _vm.selectItem(item.id)},"delete":function($event){return _vm.segmentDeleteModal(item)},"edit":function($event){_vm.$modal.open({
                    name: _vm.MODAL_TITLE.SEGMENT_EDIT,
                    right: true,
                    props: {
                      segment: item,
                      projectId: _vm.activeProjectId,
                      catalogId: _vm.catalogId,
                      callback: _vm.editSegmentCallback(item)
                    }
                  })}}})}),1)],2),(_vm.showDialog)?_c('v-dialog',_vm._b({},'v-dialog',_vm.dialogProps,false)):_vm._e(),_c('v-pagination',{attrs:{"show-count-element":false,"show-limit-selector":false,"page-number":_vm.$route.query.page,"limit":_vm.data.limit,"count":_vm.data.total}})],1)],1)])]:_c('v-error',{attrs:{"title":_vm.$t('modules.pages')['Error'],"subtitle":_vm.$t('modules.pages')['No access to projects']}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }